$(function () {
  // 列表页的js
  if (location.pathname == "/view/case.html") {
    $(".js-loading").show();

    var curPage = util.getQueryString("page") || 1;
    var cateGoryId = util.getQueryString("cateGoryId") || 0;
    store.set("currentCategoryId", cateGoryId);
    var categoryUrl = location.href;

    var paginationInfo = {
      maxPage: 1,
      max_num: null,
      pageLimit: 5,
      curPage: curPage,
      categoryId: cateGoryId,
    };

    homeInitPage();

    shareWeChat(
      "拾芳 - 案例列表",
      categoryUrl,
      "http://www.shifang.tech/img/share-icon.png",
      "上海拾芳是一家掌握前沿技术的互联网软件服务公司，提供一站式技术开发服务。"
    );

    function homeInitPage() {
      // 显示拾芳公众号二维码
      showQRcode();
      // 初始化canvas
      initCanvas();
      // 获取分类
      getCategory(function () {
        // 获取案例展示信息
        getDemoList(
          paginationInfo.curPage,
          paginationInfo.pageLimit,
          util.getQueryString("cateGoryId") || 0
        );
        if (store.get("currentCategoryId")) {
          var id = $(".tags-list .item").data("categoryId");
          $.each($(".tags-list .item"), function (key, value) {
            if (value.dataset.categoryId == store.get("currentCategoryId")) {
              $(this).addClass("active");
            }
          });
        } else {
          $(".tags-list .item:first-child").addClass("active");
        }
      });
      // // 初始化分页
      // initPagination();
      // 回到顶部
      toTop();
    }
    // 显示拾芳公众号二维码
    function showQRcode() {
      var contactQRcodeHandle = function () {
        if ($(".our-qrcode").hasClass("open")) {
          $(".contrl-left .arrow").css(
            "transform",
            " scaleY(1.8) rotate(0deg) translateY(-1px)"
          );
          $(".our-qrcode").removeClass("open");
        } else {
          $(".our-qrcode").addClass("open");
          $(".contrl-left .arrow").css(
            "transform",
            " scaleY(1.8) rotate(180deg) translateY(-1.2px)"
          );
        }
      };
      $(".our-qrcode .contrl-left").click(function () {
        contactQRcodeHandle();
      });
      if (window.innerWidth < 1200) {
        $(".our-qrcode").addClass("open");
      } else {
        $(".our-qrcode").removeClass("open");
      }
    }
    // 初始化canvas
    function initCanvas() {
      $(".bg-canvas").particleground({
        dotColor: "#565A66",
        lineColor: "#565A66",
        maxSpeedX: 0.3,
        maxSpeedY: 0.3,
        particleRadius: 6,
        parallaxMultiplier: 5,
        density: 10000,
      });
    }

    // 获取分类
    function getCategory(cb) {
      $(".topic-box").on("click", ".item", function (ev) {
        paginationInfo.curPage = 1;
        $(".js-loading").show();
        var currentCateGoryId = $(this).data("categoryId");
        store.set("currentCategoryId", currentCateGoryId);
        $(this).addClass("active").siblings().removeClass("active");
        var target =
          "/view/case.html?page=1" + "&categoryId=" + currentCateGoryId;
        location.href = target;

        getDemoList(
          paginationInfo.curPage,
          paginationInfo.pageLimit,
          currentCateGoryId
        );
      });
      // $(".topic-box .item").click(function() {

      // })
      getData(util.apiMap.tagList, "", function (resp) {
        var tplObj = {
          tagList: resp.tag_list,
        };
        paginationInfo.categoryId = resp.tag_list[0].id;
        bindDom("tags-tpl", tplObj, $(".tags-list"));
        cb();
      });
    }
    // 获取案例展示信息
    function getDemoList(page, limit, categoryId) {
      var mySwiper = null;
      var listData = {
        p: page,
        limit: limit,
        tag_id: categoryId,
      };

      getData(util.apiMap.listApi, listData, function (resp) {
        var demoList = resp.project_list;
        paginationInfo.maxPage = resp.max_page;
        paginationInfo.max_num = resp.max_num;
        initPagination(paginationInfo.maxPage);
        util.wait(function () {
          demoList.forEach(function (demo) {
            demo.image_detail.forEach(function (imgSrc) {
              var img = $("<img />").attr("src", imgSrc).css("display", "none");
              $("body").append(img);
            });
          });
        }, 1000);
        var tplObj = {
          demoList: demoList,
          currentList: [],
        };
        // 案例列表Dom
        bindDom("list-tpl-demo-show", tplObj, $(".demo-list"));
        // 多图案例Dom
        bindDom(
          "list-tpl-multi-img",
          tplObj,
          $(".swiper-container-multi-img .swiper-wrapper")
        );
        // 单图案例展示
        singleImgFunc();
        // 多图案例展示
        multiImgFunc();
        // 二维码展示
        var qrcode = new QRCode($(".qrcode-wrap")[0], {
          text: "null",
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H,
        });
        var qrcodeM = new QRCode($(".qrcode-wrap-m")[0], {
          text: "null",
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H,
        });
        $(".js-preview").on("click", function () {
          $("body").addClass("body-overflow-hidden");
          var imgListLength = $(this).data("targetImgLength");
          var currentId = $(this).data("targetId");
          var qrcodeHandle = function (qrcode, itemURL, qrcodeDom) {
            if (qrcode) {
              qrcode.clear();
              qrcode.makeCode(itemURL);
            } else {
              qrcode = new QRCode(qrcodeDom, {
                text: itemURL,
                width: 120,
                height: 120,
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
          };
          tplObj.demoList.forEach(function (item) {
            if (item.id == currentId) {
              tplObj.currentList = item;
              bindDom(
                "list-tpl-multi-img",
                tplObj,
                $(".swiper-container-multi-img .swiper-wrapper")
              );
              if (item.url || item.qrcode) {
                $(".js-preview-img-bc.single .m-play-wrap").attr(
                  "href",
                  item.url
                );
                $(".game-name").text(item.name);
                $(".game-desc").text("可以扫码试玩或点击跳转");
                // 0 浏览器打开 1 微信浏览器打开 2 小程序打开
                if (item.open_type == 0) {
                  $(".js-preview-img-bc.multi .m-play-wrap").attr(
                    "href",
                    item.url
                  );
                  $(".go-look").show();
                  $(".js-preview-img-bc.multi .m-play-wrap").attr(
                    "href",
                    item.url
                  );
                  $(".go-look").attr("href", item.url);
                } else {
                  // 文字根据是否是去微信浏览器打开变化
                  $(".game-desc").text("可以扫码试玩");
                  $(".go-look").hide();
                  if (isWeiXinBrowser() && item.url) {
                    $(".js-preview-img-bc.multi .m-play-wrap").attr(
                      "href",
                      item.url
                    );
                  } else {
                    // 需要用微信浏览器打开 暂时跳转到新页面里面存放该二维码
                    var qrcodeData = {
                      isQRcode: false,
                      qrcode: "",
                      links: item.url,
                    };
                    if (item.qrcode) {
                      qrcodeData.isQRcode = true;
                      qrcodeData.qrcode = item.qrcode;
                    }
                    store.set("projectLinkInfo", qrcodeData);
                    $(".js-preview-img-bc.multi .m-play-wrap").attr(
                      "href",
                      "/view/qrcode.html"
                    );
                  }
                }

                if (item.qrcode) {
                  $(".qrcode-wrap").hide();
                  $(".admin-qrcode").show();
                  $(".admin-qrcode .admin-qrcode-img").attr("src", item.qrcode);
                } else {
                  $(".admin-qrcode").hide();
                  $(".qrcode-wrap").show();
                  $(".is-play-wrap .go-look").attr("href", item.url);
                  qrcodeHandle(qrcode, item.url, $(".qrcode-wrap")[0]);
                  qrcodeHandle(qrcodeM, item.url, $(".qrcode-wrap-m")[0]);
                }

                $(".is-play-wrap").css("visibility", "visible");
                $(".m-play-wrap").css("visibility", "visible");
              } else {
                $(".is-play-wrap").css("visibility", "hidden");
                $(".m-play-wrap").css("visibility", "hidden");
              }
            }
          });
          // 多图展示处理
          if (imgListLength > 1) {
            // 显示最外边容器
            $(".js-preview-img-bc.multi").fadeIn();
            // 图片地址引入
            $(".js-preview-img-bc .swiper-slide").each(function (index, item) {
              var currentImg = $(item).find(".js-multi-img");
              currentImg.attr("src", currentImg.data("src"));
              var widthHeightRate = currentImg.width() / currentImg.height();
              var multiImg = $(this).find(".js-multi-img");
              var multiImgNative = $(this).find(".js-multi-img")[0];
              //
              if (widthHeightRate < 0.56) {
                $(this).find(".can-scroll").show();
                multiImg.addClass("active");
                util.addEvent(multiImgNative, function () {
                  $(".can-scroll").fadeOut();
                });
                multiImgNative.addEventListener(
                  "touchmove",
                  function (e) {
                    util.stopBubble(e);
                    $(".can-scroll").fadeOut();
                  },
                  { passive: false }
                );
                document.body.addEventListener(
                  "touchmove",
                  function (e) {
                    util.stopBubble(e); //阻止默认的处理方式(阻止下拉滑动的效果)
                  },
                  { passive: false }
                );
              } else {
                multiImg.removeClass("active");
                $(this).find(".can-scroll").hide();
              }
              // $(".js-preview-img-bc .multi-img").on(
              //   "mousewheel",
              //   util.stopBubble
              // );

              // $(".js-preview-img-bc .multi-img").on("touchstart", function(e) {
              //   e.preventDefault();
              //   (startX = e.originalEvent.changedTouches[0].pageX),
              //     (startY = e.originalEvent.changedTouches[0].pageY);
              // });
            });
            // 图片加载完毕显示该图片
            $(".js-single-img ").on("load", function () {
              $(".lds-css").hide();
              $(".js-single-img").fadeIn();
            });
            // 多图轮播
            myImgSwiper = new Swiper(".swiper-container-multi-img", {
              prevButton: ".swiper-button-prev",
              nextButton: ".swiper-button-next",
              loop: true,
            });
            // 单图展示处理
          } else {
            // 显示最外边容器
            $(".js-preview-img-bc.single").fadeIn();
            // 图片地址引入
            var imgSrc = $(this).data("targetSrc");
            $(".js-preview-img-bc .js-single-img").attr("src", imgSrc);
            // 图片加载完毕显示该图片
            $(".js-multi-img ").on("load", function () {
              $(".lds-css").hide();
              $(".js-single-img").fadeIn();
            });
          }
        });
        $(".js-loading").hide();
        $("img.lazy").lazyload({ effect: "fadeIn" });
      });
    }

    // 初始化分页
    function initPagination(maxPage) {
      var init = function (num) {
        $("#pagination").html("");
        new Page({
          id: "pagination",
          curPage: paginationInfo.curPage, //初始页码
          pageTotal: maxPage, //总页数
          pageAmount: 5, //每页多少条
          dataTotal: paginationInfo.max_num, //总共多少条数据
          pageSize: num, //可选,分页个数
          showPageTotalFlag: false, //是否显示数据统计
          showSkipInputFlag: false, //是否支持跳转
          getPage: function (page) {
            //获取当前页数
            paginationInfo.curPage = page;

            var target =
              "/view/case.html?page=" +
              page +
              "&categoryId=" +
              store.get("currentCategoryId");
            location.href = target;
            getDemoList(page, paginationInfo.pageLimit, categoryId);
          },
        });
      };
      var changePageNum = function () {
        if ($(document).width() < 500) {
          init(3);
        } else {
          init(5);
        }
      };
      changePageNum();
      window.addEventListener("resize", function () {
        changePageNum();
      });
    }

    // 回到顶部
    function toTop() {
      $(".js-top").click(function () {
        $("body,html").animate({ scrollTop: 0 }, 1000 / 60);
      });
    }
  }
});
