$(function () {
  // 只会在首页执行的方法
  if (location.pathname == "/") {
    store.remove("currentCategoryId");
    shareWeChat(
      "拾芳 - 首页",
      location.href.split("#")[0],
      "http://www.shifang.tech/img/share-icon.png",
      "上海拾芳是一家掌握前沿技术的互联网软件服务公司，提供一站式技术开发服务。"
    );
    homeInitPage();
    function homeInitPage() {
      // 显示拾芳公众号二维码
      showQRcode();
      // 初始化全屏插件
      initFullPage();
      // 初始化canvas
      initCanvas();
      // 初始化swiper
      initSwiper();
      // 初始化获取案例展示
      getDemoList();
    }
    // 显示拾芳公众号二维码
    function showQRcode() {
      var contactQRcodeHandle = function () {
        if ($(".our-qrcode").hasClass("open")) {
          $(".contrl-left .arrow").css(
            "transform",
            " scaleY(1.8) rotate(0deg) translateY(-1px)"
          );
          $(".our-qrcode").removeClass("open");
        } else {
          $(".our-qrcode").addClass("open");
          $(".contrl-left .arrow").css(
            "transform",
            " scaleY(1.8) rotate(180deg) translateY(-1.2px)"
          );
        }
      };
      $(".our-qrcode .contrl-left").click(function () {
        contactQRcodeHandle();
      });
      if (window.innerWidth < 1200) {
        $(".our-qrcode").addClass("open");
      } else {
        $(".our-qrcode").removeClass("open");
      }
    }
    //  全屏插件
    function initFullPage() {
      var changeDot = function (index) {
        if (index == 3 || index == 4 || index == 6) {
          $("#fp-nav ul li .fp-tooltip").css("color", "#2d313e");
          $("#fp-nav ul li a").each(function () {
            $(this).addClass("subBg");
          });
          $(".m-nav-bar i").addClass("active");
        } else {
          $("#fp-nav ul li .fp-tooltip").css("color", "#ffffff");
          $("#fp-nav ul li a").each(function () {
            $(this).removeClass("subBg");
          });
          $(".m-nav-bar i").removeClass("active");
        }
      };
      $("#fullpage").fullpage({
        verticalCentered: false,
        navigation: true,
        fitToSectionDelay: 3000,
        sectionsColor: [
          "#2d313e",
          "#D73F45",
          "#ffffff",
          "#EEF4F4",
          "#E81B26",
          "#ffffff",
        ],
        anchors: [
          "firstPage",
          "secondPage",
          "3rdPage",
          "5thPage",
          "6thPage",
          "7thPage",
        ],
        menu: "#menu",
        navigationTooltips: [
          "上海拾芳欢迎您",
          "我们提供的服务",
          "案例展示",
          "微信开发",
          "区块链",
          "合作伙伴",
        ],
        onLeave: function (anchorLink, index) {
          changeDot(index);
        },
        afterLoad: function (anchorLink, index) {
          var addAnimated = function (animatedMethod) {
            $(" [data-anchor = " + anchorLink + "] .js-" + animatedMethod)
              .removeClass("op0")
              .addClass("op10 animated " + animatedMethod + " fast");
          };
          var bounceInDownAni = function (eleDelay) {
            $(".js-bounceInDown-" + eleDelay)
              .removeClass("op0")
              .addClass("op10 animated bounceInDown fast ");
          };
          for (var i = 0; i <= 7; i++) {
            if (i == index) {
              addAnimated("fadeInUp");
            }
          }
          // TODO: 如果添加解决方案，（index===5）要更改
          if (index === 5) {
            addAnimated("bounceInRight");
            util.wait(function () {
              addAnimated("bounceInLeft");
            }, 100);
            util.wait(function () {
              bounceInDownAni(200);
            }, 300);
            util.wait(function () {
              bounceInDownAni(300);
            }, 400);
            util.wait(function () {
              bounceInDownAni(400);
            }, 700);
            util.wait(function () {
              bounceInDownAni(500);
            }, 900);
          }

          $.fn.fullpage.reBuild();
        },
      });
    }
    // 初始化canvas
    function initCanvas() {
      $(".bg-canvas").particleground({
        dotColor: "#565A66",
        lineColor: "#565A66",
        maxSpeedX: 0.3,
        maxSpeedY: 0.3,
        particleRadius: 6,
        parallaxMultiplier: 5,
        density: 12000,
      });
    }
    // 初始化swiper
    function initSwiper() {
      var mySwiper = new Swiper(".swiper-container", {
        loop: true,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        onSlideChangeEnd: function (swiper) {
          var addClass = function (ele) {
            ele.addClass("active").siblings().removeClass("active");
          };
          addClass($(".item[data-index=" + swiper.activeIndex + "]"));
          // 处理两个极端情况
          if (swiper.activeIndex == 6) {
            addClass($(".item[data-index=1]"));
          }
          if (swiper.activeIndex == 0) {
            addClass($(".item[data-index=5]"));
          }
        },
      });
      // 点击滑动导航跳转不同服务具体介绍
      $(".swiper-nav .item").on("click", function () {
        $(this).addClass("active").siblings().removeClass("active");
        mySwiper.slideTo($(this).data().index, 600, false);
      });
    }
    // 获取案例展示信息
    function getDemoList() {
      getData(util.apiMap.recommendApi, "", function (resp) {
        // 只显示三个推荐案例
        var demoList = resp.project_list.slice(0, 3);
        // 等带1秒预加载图片
        util.wait(function () {
          demoList.forEach(function (demo) {
            demo.image_detail.forEach(function (imgSrc) {
              var img = $("<img />").attr("src", imgSrc).css("display", "none");
              $("body").append(img);
            });
          });
        }, 1000);
        var tplObj = {
          demoList: demoList,
          currentList: [],
        };
        // 多图案例轮播展示
        bindDom(
          "tpl-multi-img",
          tplObj,
          $(".swiper-container-multi-img .swiper-wrapper")
        );
        // 案例展示板块
        bindDom("tpl-demo-show", tplObj, $(".demo-show-box"));
        // 移动端案例展示板块
        bindDom("tpl-m-demo-show", tplObj, $(".m-demo-list"));
        // 移动端案例展示的swiper
        var myDemoSwiper = new Swiper(".swiper-container-demo", {
          slidesPerView: 1.1,
          spaceBetween: 10,
          slidesOffsetBefore: 10,
          slidesOffsetAfter: 10,
        });
        // 单图案例展示
        singleImgFunc();
        // 多图案例展示
        multiImgFunc();
        // 二维码展示
        var qrcode = new QRCode($(".qrcode-wrap")[0], {
          text: "null",
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H,
        });
        var qrcodeM = new QRCode($(".qrcode-wrap-m")[0], {
          text: "null",
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H,
        });
        $(".js-preview").on("click", function () {
          var imgListLength = $(this).data("targetImgLength");
          var currentId = $(this).data("targetId");
          var qrcodeHandle = function (qrcode, itemURL, qrcodeDom) {
            if (qrcode) {
              qrcode.clear();
              qrcode.makeCode(itemURL);
            } else {
              qrcode = new QRCode(qrcodeDom, {
                text: itemURL,
                width: 120,
                height: 120,
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
          };
          tplObj.demoList.forEach(function (item) {
            if (item.id == currentId) {
              tplObj.currentList = item;
              bindDom(
                "tpl-multi-img",
                tplObj,
                $(".swiper-container-multi-img .swiper-wrapper")
              );
              if (item.url || item.qrcode) {
                $(".js-preview-img-bc.single .m-play-wrap").attr(
                  "href",
                  item.url
                );
                $(".game-name").text(item.name);
                $(".game-desc").text("可以扫码试玩或点击跳转");
                // 0 浏览器打开 1 微信浏览器打开 2 小程序打开

                if (item.open_type == 0) {
                  $(".go-look").show();
                  $(".js-preview-img-bc.multi .m-play-wrap").attr(
                    "href",
                    item.url
                  );
                  $(".go-look").attr("href", item.url);
                } else {
                  // 文字根据是否是去微信浏览器打开变化
                  $(".game-desc").text("可以扫码试玩");
                  $(".go-look").hide();
                  if (isWeiXinBrowser() && item.url) {
                    $(".js-preview-img-bc.multi .m-play-wrap").attr(
                      "href",
                      item.url
                    );
                  } else {
                    // 需要用微信浏览器打开 暂时跳转到新页面里面存放该二维码
                    var qrcodeData = {
                      isQRcode: false,
                      qrcode: "",
                      links: item.url,
                    };
                    if (item.qrcode) {
                      qrcodeData.isQRcode = true;
                      qrcodeData.qrcode = item.qrcode;
                    }
                    store.set("projectLinkInfo", qrcodeData);
                    $(".js-preview-img-bc.multi .m-play-wrap").attr(
                      "href",
                      "/view/qrcode.html"
                    );
                  }
                }

                if (item.qrcode) {
                  $(".qrcode-wrap").hide();
                  $(".admin-qrcode").show();
                  $(".admin-qrcode .admin-qrcode-img").attr("src", item.qrcode);
                } else {
                  $(".admin-qrcode").hide();
                  $(".qrcode-wrap").show();
                  $(".is-play-wrap .go-look").attr("href", item.url);
                  qrcodeHandle(qrcode, item.url, $(".qrcode-wrap")[0]);
                  qrcodeHandle(qrcodeM, item.url, $(".qrcode-wrap-m")[0]);
                }

                $(".is-play-wrap").css("visibility", "visible");
                $(".m-play-wrap").css("visibility", "visible");
              } else {
                $(".is-play-wrap").css("visibility", "hidden");
                $(".m-play-wrap").css("visibility", "hidden");
              }
            }
          });
          // 多图展示处理
          if (imgListLength > 1) {
            // 显示最外边容器
            $(".js-preview-img-bc.multi").fadeIn();
            // 图片地址引入
            $(".js-preview-img-bc .swiper-slide").each(function (index, item) {
              var currentImg = $(item).find(".js-multi-img");
              currentImg.attr("src", currentImg.data("src"));
              var widthHeightRate = currentImg.width() / currentImg.height();
              //
              var multiImg = $(this).find(".js-multi-img");
              var multiImgNative = $(this).find(".js-multi-img")[0];
              if (widthHeightRate < 0.56) {
                multiImg.addClass("active");
                $(this).find(".can-scroll").show();
                util.addEvent(multiImgNative, function () {
                  $(".can-scroll").fadeOut();
                });
                multiImgNative.addEventListener(
                  "touchmove",
                  function (e) {
                    util.stopBubble(e);
                    $(".can-scroll").fadeOut();
                  },
                  { passive: false }
                );
              } else {
                multiImg.removeClass("active");
                $(this).find(".can-scroll").hide();
              }
            });
            // 图片加载完毕显示该图片
            $(".js-single-img ").on("load", function () {
              $(".lds-css").hide();
              $(".js-single-img").fadeIn();
            });
            // 多图轮播
            myImgSwiper = new Swiper(".swiper-container-multi-img", {
              prevButton: ".swiper-button-prev",
              nextButton: ".swiper-button-next",
              loop: true,
            });
            // 单图展示处理
          } else {
            // 显示最外边容器
            $(".js-preview-img-bc.single").fadeIn();
            // 图片地址引入
            var imgSrc = $(this).data("targetSrc");
            $(".js-preview-img-bc .js-single-img").attr("src", imgSrc);
            // 图片加载完毕显示该图片
            $(".js-multi-img ").on("load", function () {
              $(".lds-css").hide();
              $(".js-single-img").fadeIn();
            });
          }
        });
      });
    }
    var blockTextMap = {
      publish:
        "基于 ERC20 发币，或基于 bitshares 发币发链。成熟稳定，安全可靠。适用于行业、平台资金结算，游戏积分系统，异业合作结算等场景。",
      market:
        "基于比特股的网页版交易市场。支持行情查看、提交买单卖单、替换本位货币、设定各级费率。开源技术，安全稳定；运营为王，你行你上！",
      wallet:
        "基于『比特币，以太坊，金联盟』开源接口制作的钱包应用。支持数字资产的查询、交易及转账。类 imToken DApp。",
      game:
        "基于数字货币的网页游戏，基因裂变类、Fomo3D类、接入货币类。支持按需定制，亦可按图索骥。丰富经验，快速实现。",
    };
    // 区块链主题下方默认文字
    $(".desc-text").text(blockTextMap["publish"]);
    // 区块链主题样式显示
    $(".block-wrapper .block-item").mouseover(function () {
      $(this).addClass("active").siblings(".block-item").removeClass("active");
      $(".block-desc-text").attr("src", $(this).data("text"));
      var currentText = blockTextMap[$(this).data("desc")];
      $(".desc-text").text(currentText);
    });
  }
});
