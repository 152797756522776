var api = "//admin.shifang.tech/api"
var util = {
  apiMap: {
    recommendApi: api + "/project/recommend",
    listApi: api + "/project/list",
    tagList: api + "/project/tagList",
    cardList: api + "/card/list",
    wxConfig: "//hd-api.shifang.tech/api/wechat/share/config?v=old"
  },
  // 阻止冒泡方法
  stopBubble: function(e) {
    e = window.event || event
    //如果提供了事件对象，则这是一个非IE浏览器
    if (e && e.stopPropagation) {
      //因此它支持W3C的stopPropagation()方法
      e.stopPropagation()
      //否则，我们需要使用IE的方式来取消事件冒泡
    } else {
      window.event.cancelBubble = true
    }
  },
  // 一次性定时器
  wait: function(fun, time) {
    setTimeout(fun, time)
  },
  imgShowCommon: function() {
    // 显示loading
    $(".lds-css").show()
    // 阻止全屏插件滚动
    $(".js-preview-img-bc").on("mousewheel", util.stopBubble)
  },
  destroyMultiImgSwiper: function() {
    util.wait(function() {
      if (myImgSwiper) {
        myImgSwiper.destroy(true, true)
      }
    }, 500)
  },
  getQueryString: function(name) {
    var reg = new RegExp("([?])" + name + "=([^&]*)(&|$)", "i")
    var r = decodeURIComponent(window.location.hash)
      .substr(1)
      .match(reg)
    if (r != null) return r[2]

    reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
    r = decodeURIComponent(window.location.search)
      .substr(1)
      .match(reg)
    if (r != null) return r[2]

    return null
  },
  // 滚动事件兼容

  addEvent: function(obj, fn) {
    var xEvent = "mousewheel" || "DOMMouseScroll"
    if (obj.attachEvent) {
      obj.attachEvent("on" + xEvent, fn)
    } else {
      obj.addEventListener(xEvent, fn, false)
    }
  }
}
var myImgSwiper = null
var showFlag = false
commonInit()
function commonInit() {
  // 点击三条杠显示隐藏移动端导航
  showMobileNav()
  // 图片懒加载
  imgLazyLoad()
  // 初始化404中的canvas
  initNotFoundCanvas()
  // 监听窗口变化
  changeWindowSize()
}
// 检测是否是微信浏览器
function isWeiXinBrowser() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true
  } else {
    return false
  }
}
/**
 * 防抖
 */
function debounce(func, delay) {
  var timer = null
  return function() {
    var _this = this,
      args = arguments
    clearTimeout(timer)
    timer = setTimeout(function() {
      func.apply(_this, args)
    }, delay)
  }
}

/**
 * 监听窗口变化
 */
function windowRize(cb) {
  window.onresize = debounce(cb, 200)
}
// 点击三条杠显示隐藏移动端导航
function showMobileNav() {
  $(".m-nav-bar").click(function(e) {
    util.stopBubble(e)
    if (showFlag) {
      $(".m-nav").removeClass("active")
      $(".m-nav-bar .list").removeClass("on")
      showFlag = false
    } else {
      $(".m-nav").addClass("active")
      $(".m-nav-bar .list").addClass("on")
      showFlag = true
    }
  })
  $(document).click(function() {
    $(".m-nav").removeClass("active")
    $(".m-nav-bar .list").removeClass("on")
  })
}
// 监听窗口变化
function changeWindowSize() {
  window.addEventListener("resize", function() {
    $(".m-nav").removeClass("active")
    $(".m-nav-bar .list").removeClass("on")
  })
}
// 图片懒加载
function imgLazyLoad() {
  $("img.lazy").lazyload({ effect: "fadeIn" })
}
// 获取数据
function getData(api, data, cb) {
  $.ajax({
    url: api,
    type: "post",
    data: data,
    dataType: "json",
    success: function(resp) {
      if (resp.status == 0) {
        cb(resp)
      }
    }
  })
}
// 绑定DOM
function bindDom(tplId, tplObj, bindDom) {
  var tplInfo = template(tplId, tplObj)
  bindDom.html(tplInfo)
  template.defaults.imports.maxTextNum = function(data, num) {
    return data.length <= num ? data : data.slice(0, num) + " ..."
  }
}
// 单图案例展示
function singleImgFunc() {
  var conExpandFlag = false
  var changeImgWidth = function(percentage) {
    $(".js-preview-img-bc.single .preview-img-wrapper")
      .stop()
      .animate(
        {
          width: percentage
        },
        500
      )
  }
  function close() {
    util.destroyMultiImgSwiper()
    $(".js-preview-img-bc.single").fadeOut(function() {
      $(".lds-css").hide()
      changeImgWidth("66%")
      conExpandFlag = false
      $(".js-preview-img-bc.single .js-single-img").attr("src", "")
      $("body").removeClass("body-overflow-hidden")
      $(".can-scroll").show()
    })
  }
  util.imgShowCommon()
  // 图片放大缩小
  $(".js-preview-img-bc.single .expand").click(function(e) {
    util.stopBubble(e)
    if (conExpandFlag) {
      changeImgWidth("66%")
      conExpandFlag = false
    } else {
      changeImgWidth("83%")
      conExpandFlag = true
    }
  })
  // 图片关闭
  $(".js-preview-img-bc.single .close").click(function() {
    close()
  })
  $(".js-preview-img-bc.single .preview-img-wrapper ").click(function(e) {
    util.stopBubble(e)
  })
  $(".js-preview-img-bc.single").click(function() {
    close()
  })
  util.addEvent($(".preview-img-wrapper ")[0], onMouseWheel)
  function onMouseWheel() {
    $(".can-scroll").fadeOut()
  }
  $(".preview-img-wrapper ")[0].addEventListener(
    "touchmove",
    function(e) {
      util.stopBubble(e)
      $(".can-scroll").fadeOut()
    },
    { passive: false }
  )
}
// 多图案例展示
function multiImgFunc() {
  util.imgShowCommon()
  function close() {
    util.destroyMultiImgSwiper()
    $(".js-preview-img-bc").fadeOut()
    $(".lds-css").hide()
    $("body").removeClass("body-overflow-hidden")
  }
  // 图片关闭
  $(".js-preview-img-bc.multi .close").click(function() {
    close()
  })
  $(".js-preview-img-bc.multi .preview-img-wrapper ").click(function(e) {
    util.stopBubble(e)
  })
  $(".js-preview-img-bc.multi").click(function() {
    close()
  })
}
// 初始化404中的canvas
function initNotFoundCanvas() {
  if ($(".not-found-page")) {
    $(".bg-canvas").particleground({
      dotColor: "#565A66",
      lineColor: "#565A66",
      maxSpeedX: 0.3,
      maxSpeedY: 0.3,
      particleRadius: 6,
      parallaxMultiplier: 5,
      density: 12000
    })
  }
}

// 分享
function shareWeChat(title, href, icon, desc) {
  var url = location.href

  getData(util.apiMap["wxConfig"], { url: url }, function(resp) {
    if (resp.status == 0) {
      // todo
      if (wx) {
        wx.config(resp.jsConfig)

        wx.ready(function() {
          wx.updateTimelineShareData({
            title: title, // 分享标题
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: icon // 分享图标
          })
          // 微信朋友
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: icon, // 分享图标
            success: function() {
              // alert("分享成功")
              // 用户确认分享后执行的回调函数
            },
            cancel: function() {
              // alert("取消分享")
              // 用户取消分享后执行的回调函数
            }
          })
        })
      }
    }
  })
}
