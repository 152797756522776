$(function () {
  // 只会在首页执行的方法
  if (location.pathname == "/ppt.html") {
    shareWeChat(
      "拾芳 - 介绍",
      location.href.split("#")[0],
      "http://www.shifang.tech/img/share-icon.png",
      "上海拾芳是一家掌握前沿技术的互联网软件服务公司，提供一站式技术开发服务。"
    );
    pptInitPage();

    function pptInitPage() {
      // 初始化ppt
      getPptList();

      // 初始化全屏插件
      initFullPage();
    }

    //  全屏插件
    function initFullPage() {
      $(".ppt-wrap").fullpage({
        verticalCentered: false,
        navigation: true,
        fitToSectionDelay: 2000,
        navigationPosition: "right",
      });
    }

    // 获取案例展示信息
    function getPptList() {
      var list = [];

      for (var i = 1; i <= 26; i++) {
        list.push(`../../img/ppt/幻灯片${i}.PNG`);
      }

      var tplObj = {
        pptList: list,
      };
      // 案例列表Dom
      bindDom("ppt-demo", tplObj, $(".ppt-wrap"));
    }
  }
});
