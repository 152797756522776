$(function() {
  if (location.pathname == "/view/contact.html") {
    store.remove("currentCategoryId")
    shareWeChat(
      "拾芳 - 联系我们",
      "http://www.shifang.tech/view/contact.html",
      "http://www.shifang.tech/img/share-icon.png",
      "上海拾芳是一家掌握前沿技术的互联网软件服务公司，提供一站式技术开发服务。"
    )
    // 百度地图API功能
    function initMap() {
      var map = new BMap.Map("map")
      var point = new BMap.Point(121.520754, 31.066688)
      var marker = new BMap.Marker(point) // 创建标注
      map.addOverlay(marker) // 将标注添加到地图中
      map.centerAndZoom(point, 17)
      map.enableScrollWheelZoom() //启用滚轮放大缩小
      map.addControl(new BMap.MapTypeControl()) //添加地图类型控件
      var opts = {
        width: 450, // 信息窗口宽度
        title: "上海拾芳信息科技有限公司" // 信息窗口标题
      }
      var address =
        "地址：上海节能环保科技孵化基地，沈杜公路3872号B303" +
        "</br>" +
        " <a class='contact-us' href='mailto:dlad@shifang.tech'>邮箱：dlad@shifang.tech</a>" +
        "</br>" +
        " <a class='contact-us'  href='tel:13651819160'  >手机：13651819160</a>"
      var infoWindow = new BMap.InfoWindow(address, opts) // 创建信息窗口对象
      marker.addEventListener("click", function() {
        marker.openInfoWindow(infoWindow) //开启信息窗口
      })
      marker.openInfoWindow(infoWindow)
    }
    window.addEventListener(
      "resize",
      function() {
        initMap()
      },
      { passive: true }
    )
    initMap()
    initCanvas()
    // 获取名片
    getNameCard()
    // 初始化canvas
    function initCanvas() {
      $(".bg-canvas").particleground({
        dotColor: "#565A66",
        lineColor: "#565A66",
        maxSpeedX: 0.3,
        maxSpeedY: 0.3,
        particleRadius: 6,
        parallaxMultiplier: 5,
        density: 10000
      })
    }
    // 获取名片列表
    function getNameCard() {
      $(".info-wrapper").hide()

      getData(util.apiMap.cardList, "", function(resp) {
        if (resp.card_list.length) {
          $(".card-wrap").show()
          var tplObj = {
            cardList: resp.card_list
          }
          bindDom("tpl-card-wrap", tplObj, $(".card-wrap .card-list"))
        } else {
          $(".card-wrap").hide()
          $(".info-wrapper").show()
        }
      })
    }
  }
})
