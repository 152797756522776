$(function() {
  // 列表页的js
  if (location.pathname == "/view/qrcode.html") {
    $(".create-qrcode").hide()
    if (isWeiXinBrowser()) {
      $(".target-qrcode-wrap .title").text("长按图片识别二维码")
    }
    var qrcodeInfo = store.get("projectLinkInfo")

    if (qrcodeInfo.isQRcode) {
      $(".target-qrcode-wrap-img").show()
      $(".create-qrcode").hide()
      $(".target-qrcode-wrap-img").attr("src", qrcodeInfo.qrcode)
    } else {
      $(".target-qrcode-wrap-img").hide()
      $(".create-qrcode").show()
      var qrcode = new QRCode($(".create-qrcode")[0], {
        text: qrcodeInfo.links,
        width: 140,
        height: 140,
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }
})
